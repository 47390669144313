/**
* Vertical Accordions For Squarespace 
* Copyright Will-Myers.com & Abi Bacon
* Development Lead, Abi Bacon
**/
[data-wm-plugin="vertical-accordions"] {
  --va-min-panel-height: 30vh;
  --va-max-panel-height: 80vh;
  --va-transition-duration: 500ms;
  --va-accordion-title-padding: 12px;
  --va-accordion-title-color: var(--headingMediumColor);
  --va-panel-1-background: var(--siteBackgroundColor);
  --va-panel-2-background: var(--siteBackgroundColor); 
  --va-panel-3-background: var(--siteBackgroundColor);
  --va-panel-4-background: var(--siteBackgroundColor);
  --va-border-color: black;
  --va-border-width: 1px;
  --va-icon-width: 24px;
  --va-border-radius: 0px;
}

[data-wm-plugin="vertical-accordions"]  {
  border: var(--va-border-width) solid var(--va-border-color);
  border-radius: var(--va-border-radius);
  overflow: hidden;
}

[data-wm-plugin="vertical-accordions"] .vertical-accordions {
  display: flex;
  width: 100%;
}

[data-wm-plugin="vertical-accordions"] .accordion-panel {
  display: flex;
  min-height: var(--va-min-panel-height);
  overflow-y: hidden;
  justify-content: center;
  position: relative;
}

[data-wm-plugin="vertical-accordions"] .accordion-content-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
[data-wm-plugin="vertical-accordions"] .accordion-content {
  height: 100%;
}

/*Accordion Borders*/
@media only screen and (min-width: 767px) {
  #siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:not(:nth-of-type(1)) .accordion-title {
    border-left: var(--va-border-width) solid var(--va-border-color);
  }
}

@media only screen and (max-width: 766px) { 
  #siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:not(:nth-of-type(1)) .accordion-title {
    border-top: var(--va-border-width) solid var(--va-border-color);
  }
}

@media only screen and (min-width: 767px) {
  [data-wm-plugin="vertical-accordions"] .accordion-content-wrapper {
    width: 0px;
    transition: width var(--va-transition-duration) ease;
  }

  [data-wm-plugin="vertical-accordions"] .accordion-panel.panel-active .accordion-content-wrapper {
    width: var(--va-active-width);
  }

  [data-wm-plugin="vertical-accordions"] .accordion-content {
    width: var(--va-active-width);
  }
}

[data-wm-plugin="vertical-accordions"] .accordion-content-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

[data-wm-plugin="vertical-accordions"] .accordion-title {
  display: flex;
  gap: 17px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction:column;
  transform: rotate(0deg);
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  border-width: var(--va-accordion-button-border-width, 0px);
  border-color: var(--va-accordion-button-border-color, currentColor);
  border-style: var(--va-accordion-button-border-style, solid);
  align-items: center;
}

[data-wm-plugin="vertical-accordions"] .accordion-title:focus:focus-visible {
  outline: 2px solid currentColor;
  outline-offset: -4px;
}

[data-wm-plugin="vertical-accordions"] .accordion-title .text {
  color: var(--va-accordion-title-color);
  padding: var(--va-accordion-title-padding);
  margin: 0px;
  font-family: var(--va-accordion-title-font-family, inherit);
  font-size: var(--va-accordion-title-size, inherit);
  text-transform: var(--va-accordion-title-text-transform, inherit);
  letter-spacing: var(--va-accordion-title-letter-spacing, inherit);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  width: fit-content;
}

[data-wm-plugin="vertical-accordions"] .accordion-title .icon-wrapper {
  display: grid;
  place-items: center;
  align-self: center;
}

[data-wm-plugin="vertical-accordions"] .accordion-title .icon-wrapper svg {
  width: var(--va-icon-width);
  transition: var(--va-transition-duration);
  color: var(--va-accordion-title-color);
}

[data-wm-plugin="vertical-accordions"] .panel-active .accordion-title .icon-wrapper svg {
  transform: rotate(90deg);
}

[data-wm-plugin="vertical-accordions"] #sections {
  display:grid;
  min-height: 100%;
}

[data-wm-plugin="vertical-accordions"] #sections:nth-of-type(1) section {
  padding-top: 0px;
}

@media only screen and (min-width: 767px) {
  [data-wm-plugin="vertical-accordions"] #sections {
    max-height: var(--va-max-panel-height);
  }

  [data-wm-plugin="vertical-accordions"] .accordion-content > [data-type="item"] {
    max-height: var(--va-max-panel-height);
    overflow: scroll;
  }
}

/*Flip to Stacked on Mobile*/
@media only screen and (max-width: 766px) {
  [data-wm-plugin="vertical-accordions"] .vertical-accordions {
    flex-direction: column;
  }

  [data-wm-plugin="vertical-accordions"] .accordion-panel {
    flex-direction: column;
    height: auto;
  }

  [data-wm-plugin="vertical-accordions"] .accordion-panel {
    min-height: unset;
  }

  [data-wm-plugin="vertical-accordions"] .accordion-title {
    flex-direction: row;
    max-height: initial;
  }
  [data-wm-plugin="vertical-accordions"] .accordion-title .text {
    writing-mode: initial;
  }
  
  [data-wm-plugin="vertical-accordions"] .panel-active .accordion-title .icon-wrapper svg {
    transform: rotate(180deg);
  }

  [data-wm-plugin="vertical-accordions"] .accordion-content-wrapper {
    max-height: 0px;
    transition: max-height var(--va-transition-duration) ease;
  }

  [data-wm-plugin="vertical-accordions"] .accordion-panel.panel-active .accordion-content-wrapper {
    max-height: calc(var(--va-active-height) + 1px);
  }

  [data-wm-plugin="vertical-accordions"] .accordion-panel.panel-active .accordion-content {
    height: 100%;
  }

  [data-wm-plugin="vertical-accordions"] .accordion-content>* {
    max-height: fit-content;
  }

  [data-wm-plugin="vertical-accordions"] .accordion-content > *  {
    min-height: 100%;
  }
}

/* Classic Editor within Fluid Engine */
[data-wm-plugin="vertical-accordions"] .sqs-block-image .design-layout-fluid img {
    height: auto;
}

#siteWrapper [data-fluid-engine="true"] [data-wm-plugin="vertical-accordions"] .content-wrapper:has(.sqs-layout) {
  padding: 0px;
}

[data-wm-plugin="vertical-accordions"] .accordion-content > [data-type="item"] {
  padding: 3.3vmax;
}

[data-wm-plugin="vertical-accordions"] .accordion-content > [data-type="item"] .sqs-block {
  box-sizing: border-box;
  padding: 17px;
}


/** Multi-colored Accordion **/
[data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(1) .accordion-title {
  background-color: var(--va-panel-1-background);
}

#siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(1) .accordion-title:hover {
  background-color: var(--va-panel-hover-1-background, var(--va-panel-1-background));
}

[data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(2) .accordion-title {
  background-color: var(--va-panel-2-background) ;
}

#siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(2) .accordion-title:hover {
  background-color: var(--va-panel-hover-2-background, var(--va-panel-2-background));
}


[data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(3) .accordion-title {
  background-color: var(--va-panel-3-background);
}

#siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(3) .accordion-title:hover {
  background-color: var(--va-panel-hover-3-background, var(--va-panel-3-background));
}

[data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(4) .accordion-title {
  background-color: var(--va-panel-4-background);
}

#siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(4) .accordion-title:hover {
  background-color: var(--va-panel-hover-4-background, var(--va-panel-4-background));
}

[data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(5) .accordion-title {
  background-color: var(--va-panel-5-background);
}

#siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(5) .accordion-title:hover {
  background-color: var(--va-panel-hover-5-background, var(--va-panel-5-background));
}

[data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(6) .accordion-title {
  background-color: var(--va-panel-6-background);
}

#siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(6) .accordion-title:hover {
  background-color: var(--va-panel-hover-6-background, var(--va-panel-6-background));
}

[data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(7) .accordion-title {
  background-color: var(--va-panel-7-background);
}

#siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(7) .accordion-title:hover {
  background-color: var(--va-panel-hover-7-background, var(--va-panel-7-background));
}

[data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(8) .accordion-title {
  background-color: var(--va-panel-8-background);
}

#siteWrapper [data-wm-plugin="vertical-accordions"] .accordion-panel:nth-of-type(8) .accordion-title:hover {
  background-color: var(--va-panel-hover-8-background, var(--va-panel-8-background));
}

.sqs-edit-mode-active .code-block [data-wm-plugin="vertical-accordions"] {
  color: transparent;
  background: transparent;
  font-size: 0;
  position: absolute;
  line-height: 0;
  width: 100%;
  height: 25px;
  border: 1px dashed var(--headingLargeColor);
}

.sqs-edit-mode-active .code-block [data-wm-plugin="vertical-accordions"]::after {
  content: 'Vertical Accordions';
  color: var(--headingLargeColor);
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform:translateX(25%);
}
